export enum Resource {
  CrewChanges,
  DetailedCrewChange,
  CrewSupervisor,
  CrewOperator,
  PortAgent,
  Manning,
  Analytics,
  Seafarer,
  Port,
  COVIDRestrictions,
  Visa,
  CrewChangeComment,
  CrewChangeVisaRequirement,
  CrewChangeCovidRestriction,
  Documents,
  VesselSchedule,
  Vessel,
  FlightBooking,
  Landing,
  Tenant,
  Airports,
  Airlines,
  TravelWindow,
  PortPlanning,
  TenantPreferences,
  TravelOfficeConfiguration,
  Notifications,
  DetailedRoleDisplayName,
  PortPlanningScenario,
  PortPlanningScenarioReport,
  OmniSearch,
}
