import clsx from 'clsx'
import React, { MouseEvent, MouseEventHandler, ReactNode } from 'react'
import { TrackEventProps, useTrackEvent } from '~hooks/useTrackEvent'
import { UiSizes, UiVariants } from '../types'
import { getButtonVariantClasses } from './button.types'

export interface ButtonProps {
  className?: string
  clickTrackingEvent?: TrackEventProps
  title?: string
  variant?: UiVariants
  size?: UiSizes
  children?: ReactNode
  href?: string
  onClick?: (e?: MouseEvent<Element>) => void
  passEvent?: boolean
  disabled?: boolean
  target?: string
  fullWidth?: boolean
  type?: 'button' | 'submit' | 'reset'
}

const Button = React.forwardRef<HTMLButtonElement & HTMLAnchorElement, ButtonProps>(function Button(
  props: ButtonProps,
  ref
) {
  const {
    clickTrackingEvent,
    variant = 'default',
    children,
    title,
    href,
    disabled = false,
    onClick,
    target,
    fullWidth = false,
    type = 'button',
    className,
    passEvent = false,
    size = 'regular',
  } = props

  const Component = typeof href === 'string' ? 'a' : 'button'

  const baseClass = `inline-flex items-center leading-none rounded-lg transition-colors font-medium`

  const sizeClasses = clsx({
    'h-8 text-xs px-2': size === 'small',
    'h-9 text-sm px-3': size === 'regular',
    'h-10 text-base px-4': size === 'large',
    '': size === 'custom',
  })

  const variantClasses = getButtonVariantClasses(variant, disabled, fullWidth)

  const dynamicClasses = clsx(baseClass, className, variantClasses.all, sizeClasses)

  const trackEvent = useTrackEvent()

  const handleClick: MouseEventHandler = (e: MouseEvent) => {
    if (onClick && !passEvent) {
      e.preventDefault()
      e.stopPropagation()
    }

    if (clickTrackingEvent) {
      if (!clickTrackingEvent.metadata) {
        trackEvent({ event: clickTrackingEvent.event })
      } else {
        trackEvent({ ...clickTrackingEvent })
      }
    }
    return onClick?.(e)
  }

  return (
    <Component
      className={dynamicClasses}
      href={href}
      onClick={handleClick}
      disabled={disabled}
      ref={ref}
      target={target}
      type={href ? undefined : type}
      title={title}
    >
      {children}
    </Component>
  )
})

export default Button
