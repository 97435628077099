import { Action } from './action'
import { Permissions } from './permissions'
import { Resource } from './resource'

export enum Role {
  CrewManager = 'Crew Manager',
  CrewSupervisor = 'Crew Supervisor',
  CrewOperator = 'Crew Operator',
  PortAgent = 'Port Agent',
  Manning = 'Manning',
  TillaBackoffice = 'Tilla Backoffice',
  VesselMaster = 'Vessel Master',
  TravelAgent = 'Travel Agent',
  Public = 'Public',
}

const roles = new Map<Role, Permissions>([
  [
    Role.CrewSupervisor,
    {
      create: [
        Resource.CrewOperator,
        Resource.Manning,
        Resource.PortAgent,
        Resource.CrewChangeComment,
        Resource.CrewChangeVisaRequirement,
        Resource.Documents,
      ],
      read: [
        Resource.Airports,
        Resource.Airlines,
        Resource.Analytics,
        Resource.Vessel,
        Resource.VesselSchedule,
        Resource.CrewChanges,
        Resource.DetailedCrewChange,
        Resource.Seafarer,
        Resource.Port,
        Resource.COVIDRestrictions,
        Resource.Visa,
        Resource.Documents,
        Resource.FlightBooking,
        Resource.TravelWindow,
        Resource.PortPlanning,
        Resource.TenantPreferences,
        Resource.CrewSupervisor,
        Resource.Notifications,
      ],
      update: [
        Resource.CrewOperator,
        Resource.Manning,
        Resource.PortAgent,
        Resource.CrewChangeCovidRestriction,
        Resource.CrewChangeVisaRequirement,
        Resource.Vessel,
        Resource.Notifications,
      ],
      delete: [Resource.CrewOperator, Resource.Manning, Resource.PortAgent],
      perform: [
        Action.AssignCrewOperator,
        Action.SearchFlight,
        Action.BookFlight,
        Action.ToggleBookingMonitoring,
        Action.RebookFlight,
        Action.IssueTicket,
        Action.CancelTicket,
        Action.DeactivateBooking,
        Action.RepriceBooking,
        Action.RequestConfirmation,
        Action.UpdateCrewChangeStatus,
        Action.CancelFlight,
        Action.DeferFlightToTravelAgent,
        Action.CancelFlightDeferral,
        Action.ShareFlightDetails,
        Action.InvitePortAgent,
        Action.InviteTravelAgent,
        Action.CreateBookingFromPnr,
        Action.CreateBookingFromScratch,
        Action.EditBookingFromScratch,
        Action.UpdatePNR,
        Action.EditPNR,
        Action.CancelPNR,
        Action.RefundFlight,
        Action.ValidatePort,
        Action.FetchBookingHistory,
        Action.CreateCrewChange,
        Action.DataSync,
        Action.CancelCrewChange,
        Action.RestoreCrewChange,
        Action.UpdateCrewChange,
        Action.UseOmniSearch,
      ],
    },
  ],
  [
    Role.CrewOperator,
    {
      create: [
        Resource.Manning,
        Resource.PortAgent,
        Resource.CrewChangeComment,
        Resource.CrewChangeCovidRestriction,
        Resource.CrewChangeVisaRequirement,
        Resource.Documents,
        Resource.VesselSchedule,
      ],
      read: [
        Resource.Airports,
        Resource.Airlines,
        Resource.Analytics,
        Resource.CrewChanges,
        Resource.Vessel,
        Resource.VesselSchedule,
        Resource.DetailedCrewChange,
        Resource.Seafarer,
        Resource.COVIDRestrictions,
        Resource.Visa,
        Resource.Documents,
        Resource.FlightBooking,
        Resource.TravelWindow,
        Resource.PortPlanning,
        Resource.CrewOperator,
        Resource.TenantPreferences,
        Resource.Notifications,
        Resource.Port,
      ],
      delete: [Resource.Manning, Resource.VesselSchedule],
      update: [
        Resource.Manning,
        Resource.PortAgent,
        Resource.Seafarer,
        Resource.CrewChangeCovidRestriction,
        Resource.CrewChangeVisaRequirement,
        Resource.Vessel,
        Resource.VesselSchedule,
        Resource.Notifications,
      ],
      perform: [
        Action.AssignCrewOperator,
        Action.SearchFlight,
        Action.CreateBookingFromPnr,
        Action.CreateBookingFromScratch,
        Action.EditBookingFromScratch,
        Action.UpdatePNR,
        Action.EditPNR,
        Action.CancelPNR,
        Action.BookFlight,
        Action.RebookFlight,
        Action.ToggleBookingMonitoring,
        Action.IssueTicket,
        Action.CancelTicket,
        Action.DeactivateBooking,
        Action.RepriceBooking,
        Action.RequestConfirmation,
        Action.UpdateCrewChangeStatus,
        Action.CancelFlight,
        Action.DeferFlightToTravelAgent,
        Action.CancelFlightDeferral,
        Action.ShareFlightDetails,
        Action.InvitePortAgent,
        Action.InviteTravelAgent,
        Action.EditSeafarersInHanseatic,
        Action.StartCrewChange,
        Action.FetchETicket,
        Action.RefundFlight,
        Action.ValidatePort,
        Action.FetchBookingHistory,
        Action.CreateCrewChange,
        Action.DataSync,
        Action.CancelCrewChange,
        Action.RestoreCrewChange,
        Action.UpdateCrewChange,
        Action.UseOmniSearch,
      ],
    },
  ],
  [
    Role.Manning,
    {
      create: [Resource.CrewChangeComment, Resource.CrewChangeVisaRequirement, Resource.Documents],
      read: [
        Resource.Airports,
        Resource.Airlines,
        Resource.CrewChanges,
        Resource.Vessel,
        Resource.VesselSchedule,
        Resource.DetailedCrewChange,
        Resource.Seafarer,
        Resource.COVIDRestrictions,
        Resource.Visa,
        Resource.Documents,
        Resource.FlightBooking,
        Resource.Port,
        Resource.TravelWindow,
        Resource.PortPlanning,
      ],
      update: [Resource.CrewChangeVisaRequirement],
      perform: [Action.ModifyVisaDetails, Action.ValidatePort],
    },
  ],
  [
    Role.PortAgent,
    {
      create: [
        Resource.CrewChangeComment,
        Resource.CrewChangeCovidRestriction,
        Resource.CrewChangeVisaRequirement,
        Resource.Documents,
      ],
      read: [
        Resource.Analytics,
        Resource.CrewChanges,
        Resource.DetailedCrewChange,
        Resource.Seafarer,
        Resource.COVIDRestrictions,
        Resource.Visa,
        Resource.Documents,
        Resource.FlightBooking,
        Resource.Port,
        Resource.TravelWindow,
        Resource.PortPlanning,
      ],
      update: [
        Resource.COVIDRestrictions,
        Resource.CrewChangeComment,
        Resource.CrewChangeCovidRestriction,
        Resource.CrewChangeVisaRequirement,
      ],
      delete: [
        Resource.CrewChangeComment,
        Resource.CrewChangeCovidRestriction,
        Resource.CrewChangeVisaRequirement,
      ],
      perform: [Action.ModifyCOVIDRestrictions, Action.ModifyVisaDetails, Action.ValidatePort],
    },
  ],
  [
    Role.TravelAgent,
    {
      create: [Resource.Documents],
      read: [
        Resource.Airports,
        Resource.Airlines,
        Resource.CrewChanges,
        Resource.Seafarer,
        Resource.DetailedCrewChange,
        Resource.FlightBooking,
        Resource.TravelWindow,
        Resource.PortPlanning,
        Resource.Documents,
        Resource.TenantPreferences,
        Resource.TravelOfficeConfiguration,
      ],
      perform: [
        Action.SearchFlight,
        Action.CreateBookingFromPnr,
        Action.CreateBookingFromScratch,
        Action.EditBookingFromScratch,
        Action.DeletePNR,
        Action.CancelPNR,
        Action.UpdatePNR,
        Action.EditPNR,
        Action.LoadPNRInfo,
        Action.DetectFlightItineraryChanges,
        Action.NotifyFlightsBooked,
        Action.ValidatePort,
        Action.RefundFlight,
        Action.FetchBookingHistory,
        Action.ParseFlightOffers,
      ],
      update: [Resource.TravelOfficeConfiguration],
    },
  ],
  [
    Role.VesselMaster,
    {
      create: [Resource.CrewChangeComment, Resource.CrewChangeVisaRequirement],
      read: [
        Resource.Airports,
        Resource.Airlines,
        Resource.CrewChanges,
        Resource.DetailedCrewChange,
        Resource.Vessel,
        Resource.VesselSchedule,
        Resource.Seafarer,
        Resource.Port,
        Resource.COVIDRestrictions,
        Resource.Visa,
        Resource.Documents,
        Resource.FlightBooking,
        Resource.TravelWindow,
        Resource.PortPlanning,
      ],
      perform: [Action.ModifyVisaDetails, Action.ValidatePort],
    },
  ],
  [
    Role.TillaBackoffice,
    {
      create: [
        Resource.Manning,
        Resource.PortAgent,
        Resource.CrewChangeComment,
        Resource.CrewChangeCovidRestriction,
        Resource.CrewChangeVisaRequirement,
        Resource.VesselSchedule,
        Resource.Documents,
        Resource.TenantPreferences,
        Resource.CrewChanges,
        Resource.TravelOfficeConfiguration,
        Resource.PortPlanningScenario,
        Resource.PortPlanningScenarioReport,
      ],
      read: [
        Resource.Airports,
        Resource.Airlines,
        Resource.Analytics,
        Resource.CrewChanges,
        Resource.DetailedCrewChange,
        Resource.Seafarer,
        Resource.Port,
        Resource.Vessel,
        Resource.VesselSchedule,
        Resource.COVIDRestrictions,
        Resource.Visa,
        Resource.Documents,
        Resource.CrewChangeComment,
        Resource.FlightBooking,
        Resource.Landing,
        Resource.TravelWindow,
        Resource.PortPlanning,
        Resource.CrewOperator,
        Resource.TenantPreferences,
        Resource.TravelOfficeConfiguration,
        Resource.Notifications,
        Resource.DetailedRoleDisplayName,
        Resource.PortPlanningScenario,
        Resource.PortPlanningScenarioReport,
      ],
      delete: [
        Resource.Manning,
        Resource.Landing,
        Resource.TenantPreferences,
        Resource.Documents,
        Resource.VesselSchedule,
        Resource.PortPlanningScenario,
        Resource.PortPlanningScenarioReport,
      ],
      update: [
        Resource.Manning,
        Resource.PortAgent,
        Resource.Seafarer,
        Resource.VesselSchedule,
        Resource.CrewChangeCovidRestriction,
        Resource.CrewChangeVisaRequirement,
        Resource.Landing,
        Resource.Tenant,
        Resource.Vessel,
        Resource.Documents,
        Resource.TenantPreferences,
        Resource.CrewChanges,
        Resource.TravelOfficeConfiguration,
        Resource.Notifications,
        Resource.PortPlanningScenario,
        Resource.PortPlanningScenarioReport,
      ],
      perform: [
        Action.AssignCrewOperator,
        Action.SearchFlight,
        Action.BookFlight,
        Action.RebookFlight,
        Action.ToggleBookingMonitoring,
        Action.IssueTicket,
        Action.DeleteTST,
        Action.CancelTicket,
        Action.DeactivateBooking,
        Action.RepriceBooking,
        Action.RequestConfirmation,
        Action.UpdateCrewChangeStatus,
        Action.CancelFlight,
        Action.DeferFlightToTravelAgent,
        Action.CancelFlightDeferral,
        Action.ShareFlightDetails,
        Action.InvitePortAgent,
        Action.InviteTravelAgent,
        Action.CreateBookingFromPnr,
        Action.CreateBookingFromScratch,
        Action.EditBookingFromScratch,
        Action.EditPNR,
        Action.DeletePNR,
        Action.CancelPNR,
        Action.UpdatePNR,
        Action.LoadPNRInfo,
        Action.DetectFlightItineraryChanges,
        Action.StartCrewChange,
        Action.NotifyFlightsBooked,
        Action.FetchETicket,
        Action.RefundFlight,
        Action.ValidatePort,
        Action.FetchBookingHistory,
        Action.NotifyLastTicketingDateNearby,
        Action.ModifyCOVIDRestrictions,
        Action.ModifyVisaDetails,
        Action.EditSeafarersInHanseatic,
        Action.ResetCrewChange,
        Action.ResetPortPlanning,
        Action.ParseFlightOffers,
        Action.CreateCrewChange,
        Action.DataSync,
        Action.CancelCrewChange,
        Action.RestoreCrewChange,
        Action.UpdateCrewChange,
        Action.UseOmniSearch,
      ],
    },
  ],
  [
    Role.Public,
    {
      create: [],
      read: [],
      update: [],
      delete: [],
      perform: [],
    },
  ],
])

// Extended Roles
// These are roles that should extend other roles (inherent their permissions and also have additional permissions)

// CrewManager Role (extends CrewOperator Role)
roles.set(Role.CrewManager, {
  create: [...(roles.get(Role.CrewOperator)?.create ?? [])],
  read: [...(roles.get(Role.CrewOperator)?.read ?? []), Resource.DetailedRoleDisplayName],
  delete: [...(roles.get(Role.CrewOperator)?.delete ?? [])],
  update: [...(roles.get(Role.CrewOperator)?.update ?? [])],
  perform: [...(roles.get(Role.CrewOperator)?.perform ?? [])],
})

export { roles }
